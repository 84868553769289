<template>
  <v-card class="elevation-1" :loading="loading">
    <v-card-title class="py-9">
      Wallet
    </v-card-title>
    <v-card-text>
      <v-form>
        <!-- employee -->
        <p class="text-xl-h6">Balance: 
          <span class="red--text">
            <strong>{{ Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(balance) }}</strong>
          </span>
        </p>

        <v-row class="mt-0 pt-0">
          <v-col cols="12" lg="6" class="pt-0 mt-0">
            <v-autocomplete
              v-model="mode"
              outlined
              dense
              clearable
              label="Mode of payout"
              item-text="title"
              item-value="id"
              :items="['CHECK','GCASH','BPI', 'BDO']"
              :class="formErrorFields.modeErrorMessage.length > 0 ? 'mb-3' : ''"
              :error-messages="formErrorFields.modeErrorMessage"
              :disabled="balance===0"
              @change="formError().remove('mode', formErrorFields)"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" lg="6" class="pt-0 mt-0">
            <!-- Amount -->
            <v-text-field
              v-model="amount"
              label="Amount"
              outlined
              dense
              type="number"
              placeholder="0.00"
              :class="formErrorFields.amountErrorMessage.length > 0 ? 'mb-3' : ''"
              :error-messages="formErrorFields.amountErrorMessage"
              :disabled="balance===0"
              @keydown="formError().remove('amount', formErrorFields)"
            ></v-text-field>
          </v-col>
        </v-row>
        
        <v-textarea
          v-model="remarks"
          outlined
          dense
          clearable
          label="Remarks"
          :class="formErrorFields.remarksErrorMessage.length > 0 ? 'mb-3' : ''"
          rows="3"
          :error-messages="formErrorFields.remarksErrorMessage"
          :disabled="balance===0"
          @keydown="formError().remove('remarks', formErrorFields)"
        ></v-textarea>

        <v-alert
          color="error"
          class="caption"
          dark
          border="left"
          prominent
        >
          Please be advised that we will be deducting a Php 30.00 service charge to the total amount.
        </v-alert>

        <v-btn
          color="success"
          :disabled="balance===0"
          :loading="loading"
          @click="submit">
          Submit
        </v-btn>
        <v-btn
          outlined
          class="mx-2"
          :disabled="balance===0"
          @click="reset"
        >
          Cancel
        </v-btn>
      </v-form>
    </v-card-text>

    <snackbar
      v-if="responseMessage.length > 0"
      :show="responseMessage.length > 0"
      :text="responseMessage"
      :color="responseMessageStatus"
      @close="responseMessage=''"
    ></snackbar>
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import crud from '@/composables/crud'
import get from '@/composables/get'
import snackbar from '@/components/SnackBar.vue'
import formError from '@/composables/formErrorHandler/formError'

export default {
  props: ['data'],
  setup(props, { emit }) {
    const balance = ref(0)
    const loading = ref(false)
    const message = ref('')
    const mode = ref('')
    const amount = ref(null)
    const remarks = ref('')
    const formErrorFields = ref({
      modeErrorMessage: '',
      amountErrorMessage: '',
      remarksErrorMessage: '',
    })

    watch(() => props.data, () => {
      mode.value = props.data.deduction_id
      amount.value = props.data.amount.replace(',', '')
      remarks.value = props.data.remarks
    })

    const {
      store,
      responseMessage,
      responseMessageStatus,
    } = crud()

    const getBalance = async () => {
      await get(`${process.env.VUE_APP_URI}/api/stockist/wallet/balance`, balance, loading)
    }

    getBalance()

    const reset = () => {
      mode.value = ''
      amount.value = null
      remarks.value = ''
    }

    const submit = async () => {
      const formData = {
        mode: mode.value,
        amount: amount.value,
        remarks: remarks.value,
      }

      const uri = `${process.env.VUE_APP_URI}/api/stockist/wallet/withdraw`
      await store(uri, loading, formData, formErrorFields)

      message.value = responseMessage.value
      if (responseMessageStatus.value === 'success') {
        getBalance()
        emit('updateUniqueID')
        reset()
      }
    }

    const setEmployeeId = val => {
      employee.value = val
    }

    return {
      mode,
      amount,
      remarks,
      reset,
      submit,
      loading,
      message,
      formErrorFields,
      formError,
      responseMessage,
      responseMessageStatus,
      balance,
    }
  },

  components: {
    snackbar,
  },
}
</script>
