<template>
  <v-card class="elevation-1">
    <v-card-title>
      Withdrawals
      <v-row>
        <v-col cols="12" md="4" lg="4" offset-md="8" offset-lg="8">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="mb-2"
            @keypress.enter="searchTable=search"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="searchTable"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      dense
    >
      <template #[`item.status`]="{ item }">
        <v-chip
          small
          :color="item.processed_at === null ? 'error' : 'success'"
          label
          outlined
        >
          {{ item.processed_at === null ? 'PENDING' : 'PROCESSED' }}
        </v-chip>
      </template>
      <template #[`item.amount`]="{ item }">
        {{ Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(item.amount) }}
      </template>
      <template #[`item.service_charge`]="{ item }">
        {{ Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(item.service_charge) }}
      </template>
      <template #[`item.total_amount`]="{ item }">
        {{ Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(item.total_amount) }}
      </template>
    </v-data-table>

    <snackbar
      v-if="responseMessage.length > 0"
      :show="responseMessage.length > 0"
      :text="responseMessage"
      :color="responseMessageStatus"
      @close="responseMessage=''"
    />
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import getData from '@/composables/getData'
import crud from '@/composables/crud'
import snackbar from '@/components/SnackBar.vue'

export default {
  props: ['uniqueID'],
  setup(props) {
    const search = ref('')
    const headers = ref([
      { text: 'Date/Time Requested', align: 'start', value: 'created_at' },
      { text: 'Amount Requested', align: 'end', value: 'amount' },
      { text: 'SC', align: 'end', value: 'service_charge' },
      { text: 'Total Amount', align: 'end', value: 'total_amount' },
      { text: 'Mode', align: 'start', value: 'mode' },
      { text: 'Remarks', align: 'start', value: 'remarks' },
      { text: 'Status', align: 'start', value: 'status' },
    ])
    const items = ref([])
    const totalItems = ref(0)
    const searchTable = ref('')
    const options = ref({})
    const loading = ref(false)

    const { responseMessageStatus, responseMessage } = crud()
    const listUri = `${process.env.VUE_APP_URI}/api/stockist/wallet/withdrawals`

    watch(options, () => {
      getData(listUri, items, totalItems, loading, options, search)
    })

    watch(searchTable, () => {
      options.value.page = 1
      getData(listUri, items, totalItems, loading, options, search)
    })

    watch(() => props.uniqueID, () => {
      getData(listUri, items, totalItems, loading, options, search)
    })

    return {
      search,
      headers,
      items,
      searchTable,
      options,
      totalItems,
      loading,
      snackbar,
      responseMessageStatus,
      responseMessage,
    }
  },

  components: {
    snackbar,
  },
}
</script>
